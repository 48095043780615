import ProviderModel from './ProviderModel.js';
import FileModel from './FileModel.js';
import ProductLocaleModel from './ProductLocaleModel.js';
import QuantityModel from './QuantityModel.js';
import CostingModel from './CostingModel.js';
import CustomModel from './CustomModel.js';
import DimensionModel from './DimensionModel.js';
import MiscellaneousModel from './MiscellaneousModel.js';
import GeneralStoreInformationModel from './GeneralStoreInformationModel.js';
import StoreInformationModel from './StoreInformationModel.js';
import MissingFieldModel from './MissingFieldModel.js';
export default class ProductModel {
  constructor(model) {
    if (model) {
      this.setModel(model);
    }
    else {
      this.ProductId = '00000000-0000-0000-0000-000000000000';
      this.Sku = '';
      this.Description = '';
      this.Provider = new ProviderModel();
      this.OmniMajorCategoryId = null;
      this.OmniIntermediateCategoryId = null;
      this.OmniMinorCategoryId = null;
      this.InternalStatusCode = 0;
      this.StatusCode = 0;
      this.PublishTarget = 0;
      this.PublishStatusCode = 0;
      this.SeasonCode = 0;
      this.IsSystemOnly = false;
      this.FirstFile = new FileModel(); // Peut être image ou pdf
      this.SecondFile = new FileModel(); // Peut être image ou pdf
      this.ThirdFile = new FileModel(); // Peut être image ou pdf
      this.FourthFile = new FileModel(); // Peut être image ou pdf
      this.FifthFile = new FileModel(); // Peut être image ou pdf
      this.SixthFile = new FileModel(); // Peut être image ou pdf
      this.YoutubeVideoId = '';
      this.Locales = [];
      this.WebPublicationStatus = 0;
      this.OmniPublicationStatus = 0;
      this.ProviderSku = '';
      this.Upc = '';
      this.Quantities = new QuantityModel();
      this.Costings = new CostingModel();
      this.IsPriceInStoreOnly = false;
      this.Customs = new CustomModel();
      this.BoxedProductDimensions = new DimensionModel();
      this.BuiltProductDimensions = new DimensionModel();
      this.Miscellaneous = new MiscellaneousModel();
      this.GuaranteesAndCertifications = '';
      this.FlyerImage = new FileModel();
      this.GeneralStoreInformation = new GeneralStoreInformationModel();
      this.StoreInformations = [];
      this.MemberType = 0;
      this.MissingWebFields = [];
      this.SimilarProductSkus = [];
    }
  }

  setModel(model) {
    this.ProductId = model.ProductId;
    this.Sku = model.Sku;
    this.Description = model.Description;
    this.Provider.setModel(model.Provider);
    this.OmniMajorCategoryId = model.OmniMajorCategoryId;
    this.OmniIntermediateCategoryId = model.OmniIntermediateCategoryId;
    this.OmniMinorCategoryId = model.OmniMinorCategoryId;
    this.InternalStatusCode = model.InternalStatusCode;
    this.StatusCode = model.StatusCode;
    this.PublishTarget = model.PublishTarget;
    this.PublishStatusCode = model.PublishStatusCode;
    this.SeasonCode = model.SeasonCode;
    this.IsSystemOnly = model.IsSystemOnly;
    this.FirstFile.setModel(model.FirstFile);
    this.SecondFile.setModel(model.SecondFile);
    this.ThirdFile.setModel(model.ThirdFile);
    this.FourthFile.setModel(model.FourthFile);
    this.FifthFile.setModel(model.FifthFile);
    this.SixthFile.setModel(model.SixthFile);
    this.YoutubeVideoId = model.YoutubeVideoId;
    this.Locales = model.Locales.map(function (i) {
      let vm = new ProductLocaleModel();
      vm.setModel(i);
      return vm;
    });
    this.WebPublicationStatus = model.WebPublicationStatus;
    this.OmniPublicationStatus = model.OmniPublicationStatus;
    this.ProviderSku = model.ProviderSku;
    this.Upc = model.Upc;
    this.Quantities.setModel(model.Quantities);
    this.Costings.setModel(model.Costings);
    this.IsPriceInStoreOnly = model.IsPriceInStoreOnly;
    this.Customs.setModel(model.Customs);
    this.BoxedProductDimensions.setModel(model.BoxedProductDimensions);
    this.BuiltProductDimensions.setModel(model.BuiltProductDimensions);
    this.Miscellaneous.setModel(model.Miscellaneous);
    this.GuaranteesAndCertifications = model.GuaranteesAndCertifications;
    this.FlyerImage.setModel(model.FlyerImage);
    this.GeneralStoreInformation.setModel(model.GeneralStoreInformation);
    this.StoreInformations = model.StoreInformations.map(function (i) {
      let vm = new StoreInformationModel();
      vm.setModel(i);
      return vm;
    });
    this.MemberType = model.MemberType;
    this.MissingWebFields = model.MissingWebFields.map(function (i) {
      let vm = new MissingFieldModel();
      vm.setModel(i);
      return vm;
    });
    this.SimilarProductSkus = model.SimilarProductSkus;
  }
}
