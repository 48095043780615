class FileTypeEnumWeb {
  constructor() {
    this.FirstFile = 0; // Peut être image ou pdf
    this.Flyer = 1;
    this.PesticideDataSheet = 2;
    this.SecondFile = 3; // Peut être image ou pdf
    this.ThirdFile = 4; // Peut être image ou pdf
    this.FourthFile = 5; // Peut être image ou pdf
    this.FifthFile = 6; // Peut être image ou pdf
    this.SixthFile = 7; // Peut être image ou pdf
  }

  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }

}
export let fileTypeEnumWeb = new FileTypeEnumWeb();
