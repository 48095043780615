<template>
  <div class="addproduct">
    <ValidationObserver v-slot="{ validate, errors }" ref="addProductObserver">
      <form class="md-layout">
        <md-card class="md-layout-item">
          <div class="titleSection">
            <div>
              <h1>{{ pageTitle }}</h1>
            </div>
            <div class="btnSection">
              <md-button class="btn alt" @click="showDeleteProductPopin(Product.ProductId)">{{$t('product.deleteProducts')}}</md-button>
              <md-button class="btn alt" @click="showSyncPopin(Product.ProductId)" :disabled="isSyncingProduct">{{$t('product.syncProducts')}}</md-button>
              <md-button class="md-primary md-raised" @click="submitAddProduct(validate)">{{$t('product.save')}}</md-button>
            </div>
          </div>
          <div class="contentProducts">
            <!-- <md-card-header>
              <div class="md-display-1">{{ 'Statuts' }}</div>
              <div class="flexSpaceBetween">
                <div class="flexTitle">
                  <div class="publicationStatus">
                    <div class="md-subheading">{{$t('product.webStatus')}}</div>
                    <div><enum-text v-model="Product.WebPublicationStatus" :enum="PublicationStatusEnum" ressourceName="publicationStatus"></enum-text></div>
                  </div>
                  <div class="publicationStatus">
                    <div class="md-subheading">{{$t('product.omniStatus')}}</div>
                    <div><enum-text v-model="Product.OmniPublicationStatus" :enum="PublicationStatusEnum" ressourceName="publicationStatus"></enum-text></div>
                  </div>
                </div>
                <enum-select-vue-material :enum="ProductViewModeEnum" :selected.sync="viewMode" :label="$t('product.viewMode')" ressourceName="productViewMode" :defaultValue="ProductViewModeEnum.LightWeb" />
              </div>
            </md-card-header> -->

            <md-card-content>
              <div class="section" v-show="show()">
                <div class="md-display-1">{{ 'Informations de base' }}</div>
                <provider-autocomplete-vue-material :class="{ isRequired : areOmniFieldsRequired && (Product.Provider == null || Product.Provider != null && StringHelper.IsNullOrWhitespace(Product.Provider.Name)) }"
                                                     validationName="Provider"
                                                     :validationRules="'oneOf:' + providersAutocomplete.map(s => s.Number + ' - ' + s.Name)"
                                                     validationMode="lazy"
                                                     :label="$t('product.provider')"
                                                     v-model="Product.Provider"
                                                     :options="providersAutocomplete"
                                                     v-show="show()">
                </provider-autocomplete-vue-material>
                <ValidationProvider name="ProviderSku" v-slot="{ errors, classes }" mode="lazy" v-show="show()" slim>
                  <md-field :class="classes">
                    <label for="Sku">{{$t('product.providerSku')}}</label>
                    <md-input type="text"
                              name="ProviderSku"
                              maxlength="15"
                              v-model="Product.ProviderSku" />
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider name="Upc" v-slot="{ errors, classes }" mode="lazy" v-show="show()" slim>
                  <md-field :class="classes">
                    <label for="Upc">{{$t('product.upc')}}</label>
                    <md-input type="text"
                              name="Upc"
                              maxlength="100"
                              v-model="Product.Upc" />
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="section noPadding">
                <div class="md-display-1">{{ $t('product.webInformation') }}</div>
                <div v-for="language in languages"
                     :key="language.name"
                     :id="language.name"
                     :md-label="$t(language.resourcePath)"
                     :md-template-data="{ hasError: language.hasError, isRequired: language.isRequired }">
                  <p class="md-headline" v-show="show(language.name === 'fr-CA' ? [ProductViewModeEnum.LightWeb] : null)">{{ $t(language.resourcePath) }}</p>
                  <div>
                    <product-locale
                      :locale="language.name"
                      :productLocale="getProductLocale(language.name)"
                      :hasError.sync="language.hasError"
                      :areWebFieldsRequired.sync="areWebFieldsRequired"
                      :show="show"
                    />
                  </div>
                </div>
              </div>

              <div class="section noPadding" v-show="show()">
                <div class="md-display-1">{{ $t('product.quantity') }}</div>
                <quantities :quantities="Product.Quantities" />
              </div>

              <div class="section noPadding" v-show="show()">
                <div class="md-display-1">{{ $t('product.costings') }}</div>
                <costings :costings="Product.Costings" :areOmniFieldsRequired.sync="areOmniFieldsRequired" />
              </div>

              <div class="section noPadding" v-show="show()">
                <div class="md-display-1">{{ $t('product.customs') }}</div>
                <customs :customs="Product.Customs" />
              </div>

              <div class="section noPadding" v-show="show()">
                <div class="md-display-1">{{ $t('product.dimensions') }}</div>
                <div class="md-layout-item md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <p class="md-title">{{ $t('product.box') }}</p>
                    <dimensions :dimensions="Product.BoxedProductDimensions" />
                  </div>
                  <div class="md-layout-item md-layout-item md-small-size-100">
                    <p class="md-title">{{ $t('product.builtProduct') }}</p>
                    <dimensions :dimensions="Product.BuiltProductDimensions" />
                  </div>
                </div>
              </div>

              <div class="section noPadding" v-show="show()">
                <div class="md-display-1">{{ $t('product.miscellaneous') }}</div>
                <miscellaneous :miscellaneous="Product.Miscellaneous" />
              </div>

              <div class="section" v-show="show()">
                <div class="md-display-1">{{ $t('product.guaranteesAndCertifications') }}</div>
                <ValidationProvider name="Certifications" v-slot="{ errors, classes }" mode="lazy" slim>
                  <md-field :class="classes">
                    <md-textarea type="text"
                                 name="GuaranteesAndCertifications"
                                 maxlength="250"
                                 v-model="Product.GuaranteesAndCertifications" />
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="section" v-show="show([ProductViewModeEnum.LightWeb])">
                <div class="md-display-1">{{ $t('product.webFile') }}</div>
                <div :class="{ 'md-layout-item': true, 'isRequired': areWebFieldsRequired && !hasProductFile && !hasReceivedProductFile }">
                  <ValidationProvider :rules="`${fileValidationRequired ? Product.FirstFile.Name.includes('.pdf') ? 'mimes:pdf|size:500' : 'image|size:500|minDimensionsRule:1000,1000|imageRatioRule:1000,1000' : ''}`" name="FirstFile" ref="imageProvider" v-slot="{ errors, classes }" mode="passive" slim>
                    <md-field :class="classes">
                      <md-file type="file"
                              name="FirstFile"
                              :placeholder="productFilePlaceholder"
                              accept="image/*,.pdf"
                              @change="uploadFile" />
                      <span class="md-error">{{ errors[0] }}</span>
                      <a v-if="hasProductFile" target="_blank" :href="Product.FirstFile.Url">{{$t('form.downloadFile')}}</a>
                    </md-field>
                    <button :disabled="!hasProductFile" type="button" class="md-button md-primary md-raised md-theme-default" @click="deleteFile(Product.FirstFile.FileId)">{{$t('product.deleteFile')}}</button>
                  </ValidationProvider>
                </div>
                <div>
                  <span style="color: #ff1744;" v-if="hasTemporaryProductFile">*{{$t('product.temporaryImage')}}</span>
                </div>
                <p>{{ $t('product.webFileDescription') }}</p>
              </div>

              <div class="section" v-show="show([ProductViewModeEnum.LightWeb])">
                <div class="md-display-1">{{ $t('product.webFileSecondary') }}</div>
                <div :class="{ 'md-layout-item': true, 'isRequired': areWebFieldsRequired && !hasProductSecondFile && !hasReceivedProductSecondFile }">
                  <ValidationProvider :rules="`${secondFileValidationRequired ? Product.SecondFile.Name.includes('.pdf') ? 'mimes:pdf|size:500' : 'image|size:500|minDimensionsRule:1000,1000|imageRatioRule:1000,1000' : ''}`" name="SecondFile" ref="imageProvider" v-slot="{ errors, classes }" mode="passive" slim>
                    <md-field :class="classes">
                      <md-file type="file"
                              name="SecondFile"
                              :placeholder="productSecondFilePlaceholder"
                              accept="image/*,.pdf"
                              @change="uploadSecondFile" />
                      <span class="md-error">{{ errors[0] }}</span>
                      <a v-if="hasProductSecondFile" target="_blank" :href="Product.SecondFile.Url">{{$t('form.downloadFile')}}</a>
                    </md-field>
                    <button :disabled="!hasProductSecondFile" type="button" class="md-button md-primary md-raised md-theme-default" @click="deleteFile(Product.SecondFile.FileId)">{{$t('product.deleteFile')}}</button>
                  </ValidationProvider>
                </div>
                <div>
                  <span style="color: #ff1744;" v-if="hasTemporaryProductSecondFile">*{{$t('product.temporaryImage')}}</span>
                </div>
                <p>{{ $t('product.webOtherFileDescription') }}</p>
              </div>

              <div class="section" v-show="show([ProductViewModeEnum.LightWeb])">
                <div class="md-display-1">{{ $t('product.webFileThird') }}</div>
                <div :class="{ 'md-layout-item': true, 'isRequired': areWebFieldsRequired && !hasProductThirdFile && !hasReceivedProductThirdFile }">
                  <ValidationProvider :rules="`${thirdFileValidationRequired ? Product.ThirdFile.Name.includes('.pdf') ? 'mimes:pdf|size:500' : 'image|size:500|minDimensionsRule:1000,1000|imageRatioRule:1000,1000' : ''}`" name="ThirdFile" ref="imageProvider" v-slot="{ errors, classes }" mode="passive" slim>
                    <md-field :class="classes">
                      <md-file type="file"
                              name="ThirdFile"
                              :placeholder="productThirdFilePlaceholder"
                              accept="image/*,.pdf"
                              @change="uploadThirdFile" />
                      <span class="md-error">{{ errors[0] }}</span>
                      <a v-if="hasProductThirdFile" target="_blank" :href="Product.ThirdFile.Url">{{$t('form.downloadFile')}}</a>
                    </md-field>
                    <button :disabled="!hasProductThirdFile" type="button" class="md-button md-primary md-raised md-theme-default" @click="deleteFile(Product.ThirdFile.FileId)">{{$t('product.deleteFile')}}</button>
                  </ValidationProvider>
                </div>
                <div>
                  <span style="color: #ff1744;" v-if="hasTemporaryProductThirdFile">*{{$t('product.temporaryImage')}}</span>
                </div>
                <p>{{ $t('product.webOtherFileDescription') }}</p>
              </div>

              <div class="section" v-show="show([ProductViewModeEnum.LightWeb])">
                <div class="md-display-1">{{ $t('product.webFileFourth') }}</div>
                <div :class="{ 'md-layout-item': true, 'isRequired': areWebFieldsRequired && !hasProductFourthFile && !hasReceivedProductFourthFile }">
                  <ValidationProvider :rules="`${fourthFileValidationRequired ? Product.FourthFile.Name.includes('.pdf') ? 'mimes:pdf|size:500' : 'image|size:500|minDimensionsRule:1000,1000|imageRatioRule:1000,1000' : ''}`" name="FourthFile" ref="imageProvider" v-slot="{ errors, classes }" mode="passive" slim>
                    <md-field :class="classes">
                      <md-file type="file"
                              name="FourthFile"
                              :placeholder="productFourthFilePlaceholder"
                              accept="image/*,.pdf"
                              @change="uploadFourthFile" />
                      <span class="md-error">{{ errors[0] }}</span>
                      <a v-if="hasProductFourthFile" target="_blank" :href="Product.FourthFile.Url">{{$t('form.downloadFile')}}</a>
                    </md-field>
                    <button :disabled="!hasProductFourthFile" type="button" class="md-button md-primary md-raised md-theme-default" @click="deleteFile(Product.FourthFile.FileId)">{{$t('product.deleteFile')}}</button>
                  </ValidationProvider>
                </div>
                <div>
                  <span style="color: #ff1744;" v-if="hasTemporaryProductFourthFile">*{{$t('product.temporaryImage')}}</span>
                </div>
                <p>{{ $t('product.webOtherFileDescription') }}</p>
              </div>

              <div class="section" v-show="show([ProductViewModeEnum.LightWeb])">
                <div class="md-display-1">{{ $t('product.webFileFifth') }}</div>
                <div :class="{ 'md-layout-item': true, 'isRequired': areWebFieldsRequired && !hasProductFifthFile && !hasReceivedProductFifthFile }">
                  <ValidationProvider :rules="`${fifthFileValidationRequired ? Product.FifthFile.Name.includes('.pdf') ? 'mimes:pdf|size:500' : 'image|size:500|minDimensionsRule:1000,1000|imageRatioRule:1000,1000' : ''}`" name="FifthFile" ref="imageProvider" v-slot="{ errors, classes }" mode="passive" slim>
                    <md-field :class="classes">
                      <md-file type="file"
                              name="FifthFile"
                              :placeholder="productFifthFilePlaceholder"
                              accept="image/*,.pdf"
                              @change="uploadFifthFile" />
                      <span class="md-error">{{ errors[0] }}</span>
                      <a v-if="hasProductFifthFile" target="_blank" :href="Product.FifthFile.Url">{{$t('form.downloadFile')}}</a>
                    </md-field>
                    <button :disabled="!hasProductFifthFile" type="button" class="md-button md-primary md-raised md-theme-default" @click="deleteFile(Product.FifthFile.FileId)">{{$t('product.deleteFile')}}</button>
                  </ValidationProvider>
                </div>
                <div>
                  <span style="color: #ff1744;" v-if="hasTemporaryProductFifthFile">*{{$t('product.temporaryImage')}}</span>
                </div>
                <p>{{ $t('product.webOtherFileDescription') }}</p>
              </div>

              <div class="section" v-show="show([ProductViewModeEnum.LightWeb])">
                <div class="md-display-1">{{ $t('product.webFileSixth') }}</div>
                <div :class="{ 'md-layout-item': true, 'isRequired': areWebFieldsRequired && !hasProductSixthFile && !hasReceivedProductSixthFile }">
                  <ValidationProvider :rules="`${sixthFileValidationRequired ? Product.SixthFile.Name.includes('.pdf') ? 'mimes:pdf|size:500' : 'image|size:500|minDimensionsRule:1000,1000|imageRatioRule:1000,1000' : ''}`" name="SixthFile" ref="imageProvider" v-slot="{ errors, classes }" mode="passive" slim>
                    <md-field :class="classes">
                      <md-file type="file"
                              name="SixthFile"
                              :placeholder="productSixthFilePlaceholder"
                              accept="image/*,.pdf"
                              @change="uploadSixthFile" />
                      <span class="md-error">{{ errors[0] }}</span>
                      <a v-if="hasProductSixthFile" target="_blank" :href="Product.SixthFile.Url">{{$t('form.downloadFile')}}</a>
                    </md-field>
                    <button :disabled="!hasProductSixthFile" type="button" class="md-button md-primary md-raised md-theme-default" @click="deleteFile(Product.SixthFile.FileId)">{{$t('product.deleteFile')}}</button>
                  </ValidationProvider>
                </div>
                <div>
                  <span style="color: #ff1744;" v-if="hasTemporaryProductSixthFile">*{{$t('product.temporaryImage')}}</span>
                </div>
                <p>{{ $t('product.webOtherFileDescription') }}</p>
              </div>

              <div class="section" v-show="show([ProductViewModeEnum.LightWeb])">
                <div class="md-display-1">{{ $t('product.webVideo') }}</div>
                <div :class="{ 'md-layout-item': true}">
                  <ValidationProvider name="YoutubeVideoId" v-slot="{ errors, classes }" mode="passive" slim>
                    <md-field :class="classes">
                      <md-input type="text"
                              name="Video"
                              maxlength="11"
                              :placeholder="$t('form.insertVideo')"
                              v-model="Product.YoutubeVideoId" />
                    <span class="md-error">{{ errors[0] }}</span>
                      <a v-if="hasProductVideo" target="_blank" :href="`https://www.youtube.com/watch?v=${Product.YoutubeVideoId}`">{{$t('form.view')}}</a>
                    </md-field>
                  </ValidationProvider>
                </div>
                <p>{{ $t('product.webVideoDescription') }}</p>
              </div>

              <div class="section" v-show="show()">
                <div class="md-display-1">{{ $t('product.flyerPhoto') }}</div>
                <ValidationProvider :rules="`${flyerImageValidationRequired ? 'image|size:500|imageRatioRule:1000,1000' : ''}`" name="FlyerImage" ref="flyerImageProvider" v-slot="{ errors, classes }" mode="passive" slim>
                  <md-field :class="classes">
                    <md-file type="file"
                             name="FlyerImage"
                             :placeholder="flyerImagePlaceholder"
                             @change="uploadFlyerImage" />
                    <span class="md-error">{{ errors[0] }}</span>
                    <a v-if="hasFlyerImage" target="_blank" :href="Product.FlyerImage.Url">{{$t('form.downloadFile')}}</a>
                  </md-field>
                </ValidationProvider>
                <div>
                  <span style="color: #ff1744;" v-if="hasTemporaryFlyerImage">*{{$t('product.temporaryImage')}}</span>
                </div>
                <p>{{ $t('product.flyerPhotoDescription') }}</p>
              </div>

              <div class="section noPadding">
                <div class="md-display-1">{{ 'Informations Laferté' }}</div>
                <div class="md-layout-item md-layout md-gutter row1" v-show="show([ProductViewModeEnum.LightWeb])">
                  <div class="md-layout-item md-small-size-100" :class="{ isRequired : StringHelper.IsNullOrWhitespace(Product.Sku) }">
                    <ValidationProvider rules="required" name="Sku" v-slot="{ errors, classes }" mode="lazy" slim>
                      <md-field :class="classes">
                        <label for="Sku">{{ $t('product.sku') }}</label>
                        <md-input type="text"
                                  name="Sku"
                                  maxlength="15"
                                  v-model="Product.Sku" />
                        <span class="md-error">{{ errors[0] }}</span>
                      </md-field>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="md-layout-item md-layout md-gutter row2" v-show="show()">
                  <div class="md-layout-item md-small-size-100">
                    <ValidationProvider name="ProviderNumber" v-slot="{ errors, classes }" mode="lazy" slim>
                      <md-field :class="classes">
                        <label for="ProviderNumber">{{ $t('product.providerNumber') }}</label>
                        <md-input type="text"
                                  name="ProviderNumber"
                                  maxlength="25"
                                  :value="getProviderNumber"
                                  :disabled=true />
                        <span class="md-error">{{ errors[0] }}</span>
                      </md-field>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="md-layout-item md-layout md-gutter row3" v-show="show()">
                  <div class="md-layout-item md-small-size-100" :class="{ isRequired : areOmniFieldsRequired && StringHelper.IsNullOrWhitespace(Product.Description) }">
                    <ValidationProvider name="MainDescription" v-slot="{ errors, classes }" mode="lazy" slim>
                      <md-field :class="classes">
                        <label for="MainDescription">{{$t('product.laferteDescription')}}</label>
                        <md-input type="text"
                                  name="MainDescription"
                                  maxlength="30"
                                  v-model="Product.Description" />
                        <span class="md-error">{{ errors[0] }}</span>
                      </md-field>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="md-layout-item md-layout md-gutter row4">
                  <div class="md-layout-item md-small-size-100 blockElement" v-show="show()" :class="{ hasError: errors['SeasonCode'] && errors['SeasonCode'].length > 0 }">
                    <label for="SeasonCode">{{$t('product.season')}}</label>
                    <enum-select :enum="SeasonCodeEnum" v-model="Product.SeasonCode" ressourceName="seasonCode" :defaultValue="SeasonCodeEnum.None" validationName="SeasonCode"></enum-select>
                  </div>
                  <div class="md-layout-item md-small-size-100" v-show="show([ProductViewModeEnum.LightWeb])">
                    <div class="md-layout md-gutter blockElement styleSelect">
                      <div class="md-layout-item md-small-size-100">
                        <span>
                          {{$t('product.omniMajorCategory')}}:&nbsp;
                          <omni-major-category-text v-model="Product.OmniMajorCategoryId"></omni-major-category-text>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-layout md-gutter row6">
                  <div class="md-layout-item md-layout md-gutter blockElement" v-show="show()">
                    <div class="md-layout-item md-small-size-100" :class="{ hasError: errors['PublishTarget'] && errors['PublishTarget'].length > 0 }">
                      <label for="PublishTarget">{{$t('product.publishTarget')}}</label>
                      <enum-select :enum="PublishTargetEnum" v-model="Product.PublishTarget" ressourceName="publishTarget" :defaultValue="PublishTargetEnum.None" validationName="PublishTarget" :disabled="!hasManagerPermissions"></enum-select>
                    </div>
                  </div>
                  <div class="md-layout-item md-small-size-100" v-show="show([ProductViewModeEnum.LightWeb])">
                    <div class="md-layout md-gutter blockElement styleSelect">
                      <div class="md-layout-item md-small-size-100">
                        <span>
                          {{$t('product.omniIntermediateCategory')}}:&nbsp;
                          <omni-intermediate-category-text v-model="Product.OmniIntermediateCategoryId"></omni-intermediate-category-text>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-layout md-gutter row7">
                  <div class="md-layout-item md-small-size-100" v-show="show()">
                    <div class="md-field"></div>
                  </div>
                  <div class="md-layout-item md-small-size-100" v-show="show([ProductViewModeEnum.LightWeb])">
                    <div class="md-layout md-gutter blockElement styleSelect">
                      <div class="md-layout-item md-small-size-100">
                        <span>
                          {{$t('product.omniMinorCategory')}}:&nbsp;
                          <omni-minor-category-text v-model="Product.OmniMinorCategoryId"></omni-minor-category-text>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="md-layout-item md-layout md-gutter row8" v-show="show([ProductViewModeEnum.LightWeb])">
                  <div class="md-layout-item md-layout md-gutter blockElement">
                    <div class="md-layout-item md-small-size-100" :class="{ hasError: errors['PublishTarget'] && errors['PublishTarget'].length > 0 }">
                      <label for="PublishTarget">{{$t('product.publishTarget')}}</label>
                      <enum-select :enum="PublishTargetEnum" v-model="Product.PublishTarget" ressourceName="publishTarget" :defaultValue="PublishTargetEnum.None" validationName="PublishTarget" :disabled="!hasManagerPermissions"></enum-select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="section noPadding">
                <div class="md-display-1">{{ 'Informations magasin' }}</div>
                <general-store-information  :general-store-information="Product.GeneralStoreInformation"
                                            :areOmniFieldsRequired.sync="areOmniFieldsRequired"
                                            :show="show" />
              </div>

              <div class="section noPadding" v-show="show()">
                <div class="md-display-1">{{ 'Informations par succursale' }}</div>
                <div v-for="(companyBranch, index) in companyBranches" :key="index" class="succursale">
                  <div class="md-headline">
                    <enum-text :value="companyBranch" :enum="CompanyBranchEnum" ressourceName="companyBranch" />
                  </div>
                  <store-information  :store-information="getStoreInformation(companyBranch)"
                                      :areOmniFieldsRequired.sync="areOmniFieldsRequired"
                                      :show="show"/>
                </div>
              </div>
            </md-card-content>

            <md-card-actions>
              <md-button class="md-primary md-raised" @click="submitAddProduct(validate)">{{$t('product.save')}}</md-button>
            </md-card-actions>
          </div>

        </md-card>
      </form>
    </ValidationObserver>

    <md-dialog-confirm :md-active.sync="activeDeleteProductPopin"
                       :md-title="$t('product.deleteProductPopinTitle')"
                       :md-content="$t('product.deleteProductPopinContent')"
                       :md-confirm-text="$t('product.deleteProductPopinYes')"
                       :md-cancel-text="$t('product.deleteProductPopinNo')"
                       @md-confirm="deleteProduct(productToDelete)" />

    <md-dialog-confirm :md-active.sync="activeSyncProductPopin"
                       :md-title="$t('product.syncProductPopinTitle')"
                       :md-content="$t('product.syncProductPopinContent')"
                       :md-confirm-text="$t('product.syncProductPopinYes')"
                       :md-cancel-text="$t('product.syncProductPopinNo')"
                       @md-confirm="syncProduct()" />

    <md-dialog-alert :md-active.sync="showSyncResultModal"
                     :md-title="$t('product.syncProductPopinTitle')"
                     :md-content="syncMessage"
                     :md-confirm-text="$t('product.ok')" />

                       <md-dialog-confirm :md-active.sync="activeSaveProductPopin"
                       :md-title="$t('product.saveProductPopinTitle')"
                       :md-content="saveProductPopinContent"
                       :md-confirm-text="$t('product.saveProductPopinYes')"
                       :md-cancel-text="$t('product.saveProductPopinNo')"
                       @md-confirm="saveProduct" />

  </div>
</template>

<script>
  import ProductModel from '../../models/Product.js';
  import StoreInformationModel from '@/models/GeneratedModels/StoreInformationModel.js';
  import FileModel from '@/models/GeneratedModels/FileModel.js';
  import { companyBranchEnumWeb } from '../../models/GeneratedModels/CompanyBranchEnumWeb.js';
  import { internalStatusCodeEnumWeb } from '../../models/GeneratedModels/InternalStatusCodeEnumWeb.js';
  import { statusCodeEnumWeb } from '../../models/GeneratedModels/StatusCodeEnumWeb.js';
  import { seasonCodeEnumWeb } from '../../models/GeneratedModels/SeasonCodeEnumWeb.js';
  import { productViewModeEnumWeb } from '@/models/GeneratedModels/ProductViewModeEnumWeb.js';
  import { publicationStatusEnum } from '@/models/GeneratedModels/PublicationStatusEnum.js';
  import { publishTargetEnum } from '@/models/GeneratedModels/PublishTargetEnum.js';
  import ProductLocaleModel from '@/models/GeneratedModels/ProductLocaleModel.js';
  import ProductLocale from '@/components/ProductLocale.vue';
  import OmniMajorCategoryText from '@/components/OmniMajorCategoryText.vue';
  import OmniIntermediateCategoryText from '@/components/OmniIntermediateCategoryText.vue';
  import OmniMinorCategoryText from '@/components/OmniMinorCategoryText.vue';
  import Quantities from '@/components/product/Quantities.vue';
  import Costings from '@/components/product/Costings.vue';
  import Customs from '@/components/product/Customs.vue';
  import Dimensions from '@/components/product/Dimensions.vue';
  import Miscellaneous from '@/components/product/Miscellaneous.vue';
  import StoreInformation from '@/components/product/StoreInformation.vue';
  import GeneralStoreInformation from '@/components/product/GeneralStoreInformation.vue';
  import EnumText from '@/components/EnumText.vue';
  import GuidHelper from '@/helpers/GuidHelper.js';
  import { memberTypeEnumWeb } from '@/models/GeneratedModels/MemberTypeEnumWeb.js';
  import '@/scripts/validation.js';
  import VueScrollTo from 'vue-scrollto';
  import i18n from '@/i18n';
  import { fileTypeEnumWeb } from '@/models/GeneratedModels/FileTypeEnumWeb.js';
  import { salesUnitEnumWeb } from '../../models/GeneratedModels/SalesUnitEnumWeb.js';
  import StringHelper from '@/helpers/StringHelper.js';
  import authenticatedAxios from '../../authenticatedAxios';

  var scrollOptions = {
    container: 'body',
    duration: 350,
    easing: 'linear',
    offset: -125,
    cancelable: false
  };

  export default {
    name: 'AddProduct',
    data: () => ({
      Product: new ProductModel(),
      GuidHelper: new GuidHelper(),
      StringHelper: new StringHelper(),
      CompanyBranchEnum: companyBranchEnumWeb,
      MemberTypeEnum : memberTypeEnumWeb,
      InternalStatusCodeEnum: internalStatusCodeEnumWeb,
      StatusCodeEnum: statusCodeEnumWeb,
      SeasonCodeEnum: seasonCodeEnumWeb,
      ProductViewModeEnum: productViewModeEnumWeb,
      PublicationStatusEnum: publicationStatusEnum,
      PublishTargetEnum: publishTargetEnum,
      FileTypeEnum: fileTypeEnumWeb,
      SalesUnitEnum: salesUnitEnumWeb,
      providersAutocomplete: [],
      fileValidationRequired: true,
      secondFileValidationRequired: true,
      thirdFileValidationRequired: true,
      fourthFileValidationRequired: true,
      fifthFileValidationRequired: true,
      sixthFileValidationRequired: true,
      flyerImageValidationRequired: true,
      isSyncingProduct: false,
      isUpdateProcess: false,
      hasReceivedProductFile: false,
      hasReceivedProductSecondFile: false,
      hasReceivedProductThirdFile: false,
      hasReceivedProductFourthFile: false,
      hasReceivedProductFifthFile: false,
      hasReceivedProductSixthFile: false,
      hasReceivedFlyerImage: false,
      pageTitle: '',
      languages: [
        { name: 'fr-CA', resourcePath: 'language.french', descriptionResourcePath: 'language.theFrench', hasError: false, isRequired: false },
        { name: 'en-CA', resourcePath: 'language.english', descriptionResourcePath: 'language.theEnglish', hasError: false, isRequired: false }],
      activeTab: null,
      companyBranches: [],
      activeSaveProductPopin: false,
      saveProductPopinContent: '',
      activeDeleteProductPopin: false,
      activeSyncProductPopin: false,
      productToDelete: null,
      productToSync: null,
      showSyncResultModal: false,
      syncMessage: null,
      activateMapperCategory: true,
      viewMode: productViewModeEnumWeb.LightWeb
    }),
    components: {
      Costings,
      Customs,
      Dimensions,
      GeneralStoreInformation,
      ProductLocale,
      OmniMajorCategoryText,
      OmniIntermediateCategoryText,
      OmniMinorCategoryText,
      Miscellaneous,
      StoreInformation,
      EnumText,
      Quantities
    },
    async mounted() {
      this.formErrors = this.$refs.addProductObserver.errors;
      this.companyBranches = [this.CompanyBranchEnum.Drummondville, this.CompanyBranchEnum.ActonVale, this.CompanyBranchEnum.StHyacinthe, this.CompanyBranchEnum.Longueuil];
      this.pageTitle = this.$t('product.addProduct');

      this.Product.MemberType = this.$store.state.app.userInfo.MemberType;
      if (this.$store.state.app.userInfo.MemberType === this.MemberTypeEnum.Buyer ) {
        this.Product.PublishTarget = this.PublishTargetEnum.None;
      }

      await this.getProviders();
      if (this.$route.params.id) {
        this.activateMapperCategory = false;
        await this.getProduct(this.$route.params.id);
      }
    },
    computed: {
      hasManagerPermissions() {
        return this.$store.state.app.userInfo.MemberType === this.MemberTypeEnum.Manager;
      },
      productFilePlaceholder() {
        if (this.Product.FirstFile) {
          return this.Product.FirstFile.Name ? this.Product.FirstFile.Name : this.$t('form.insertFile');
        }
        else {
          return this.$t('form.insertFile');
        }
      },
      productSecondFilePlaceholder() {
        if (this.Product.SecondFile) {
          return this.Product.SecondFile.Name ? this.Product.SecondFile.Name : this.$t('form.insertFile');
        }
        else {
          return this.$t('form.insertFile');
        }
      },
      productThirdFilePlaceholder() {
        if (this.Product.ThirdFile) {
          return this.Product.ThirdFile.Name ? this.Product.ThirdFile.Name : this.$t('form.insertFile');
        }
        else {
          return this.$t('form.insertFile');
        }
      },
      productFourthFilePlaceholder() {
        if (this.Product.FourthFile) {
          return this.Product.FourthFile.Name ? this.Product.FourthFile.Name : this.$t('form.insertFile');
        }
        else {
          return this.$t('form.insertFile');
        }
      },
      productFifthFilePlaceholder() {
        if (this.Product.FifthFile) {
          return this.Product.FifthFile.Name ? this.Product.FifthFile.Name : this.$t('form.insertFile');
        }
        else {
          return this.$t('form.insertFile');
        }
      },
      productSixthFilePlaceholder() {
        if (this.Product.SixthFile) {
          return this.Product.SixthFile.Name ? this.Product.SixthFile.Name : this.$t('form.insertFile');
        }
        else {
          return this.$t('form.insertFile');
        }
      },
      flyerImagePlaceholder() {
        if (this.Product.FlyerImage) {
          return this.Product.FlyerImage.Name ? this.Product.FlyerImage.Name : this.$t('form.insertImage');
        }
        else {
          return this.$t('form.insertImage');
        }
      },
      hasTemporaryProductFile() {
        return this.isUpdateProcess && this.Product.FirstFile && !this.Product.FirstFile.StorageId && !this.hasReceivedProductFile;
      },
      hasTemporaryProductSecondFile() {
        return this.isUpdateProcess && this.Product.SecondFile && !this.Product.SecondFile.StorageId && !this.hasReceivedProductSecondFile;
      },
      hasTemporaryProductThirdFile() {
        return this.isUpdateProcess && this.Product.ThirdFile && !this.Product.ThirdFile.StorageId && !this.hasReceivedProductThirdFile;
      },
      hasTemporaryProductFourthFile() {
        return this.isUpdateProcess && this.Product.FourthFile && !this.Product.FourthFile.StorageId && !this.hasReceivedProductFourthFile;
      },
      hasTemporaryProductFifthFile() {
        return this.isUpdateProcess && this.Product.FifthFile && !this.Product.FifthFile.StorageId && !this.hasReceivedProductFifthFile;
      },
      hasTemporaryProductSixthFile() {
        return this.isUpdateProcess && this.Product.SixthFile && !this.Product.SixthFile.StorageId && !this.hasReceivedProductSixthFile;
      },
      hasTemporaryFlyerImage() {
        return this.isUpdateProcess && this.Product.FlyerImage && !this.Product.FlyerImage.StorageId && !this.hasReceivedFlyerImage;
      },
      hasProductFile() {
        return this.isUpdateProcess && this.Product.FirstFile && this.Product.FirstFile.StorageId && !this.hasReceivedProductFile;
      },
      hasProductSecondFile() {
        return this.isUpdateProcess && this.Product.SecondFile && this.Product.SecondFile.StorageId && !this.hasReceivedProductSecondFile;
      },
      hasProductThirdFile() {
        return this.isUpdateProcess && this.Product.ThirdFile && this.Product.ThirdFile.StorageId && !this.hasReceivedProductThirdFile;
      },
      hasProductFourthFile() {
        return this.isUpdateProcess && this.Product.FourthFile && this.Product.FourthFile.StorageId && !this.hasReceivedProductFourthFile;
      },
      hasProductFifthFile() {
        return this.isUpdateProcess && this.Product.FifthFile && this.Product.FifthFile.StorageId && !this.hasReceivedProductFifthFile;
      },
      hasProductSixthFile() {
        return this.isUpdateProcess && this.Product.SixthFile && this.Product.SixthFile.StorageId && !this.hasReceivedProductSixthFile;
      },
      hasProductVideo() {
        return this.isUpdateProcess && this.Product.YoutubeVideoId;
      },
      hasFlyerImage() {
        return this.isUpdateProcess && this.Product.FlyerImage && this.Product.FlyerImage.StorageId && !this.hasReceivedFlyerImage;
      },
      getProviderNumber() {
        if (this.Product.Provider) {
          return this.Product.Provider.Number;
        }
        return '';
      },
      areWebFieldsRequired() {
        return this.Product.PublishTarget === this.PublishTargetEnum.Web || this.Product.PublishTarget === this.PublishTargetEnum.OmniAndWeb;
      },
      areOmniFieldsRequired() {
        return this.Product.PublishTarget === this.PublishTargetEnum.Omni || this.Product.PublishTarget === this.PublishTargetEnum.OmniAndWeb;
      }
    },
    methods: {
      submitAddProduct(validateForm) {
        var self = this;
        self.activeTab = null;

        validateForm().then((hasNoErrors) => {
          if (hasNoErrors) {
            if (self.validateRequiredFieldsByPublishTarget()) {
              self.saveProduct();
            } else {
              self.activeSaveProductPopin = true;
            }
          } else {
            var element = document.querySelector('.hasError');
            if (element) {
              VueScrollTo.scrollTo(element, scrollOptions);
            }

            let languageWithErrors = self.languages.find(language => language.hasError);

            if (languageWithErrors) {
              self.activeTab = languageWithErrors.name;
            }
          }
        });
      },
      validateRequiredFieldsByPublishTarget() {
        this.saveProductPopinContent = '';
        if (this.areWebFieldsRequired && this.areOmniFieldsRequired) {
          if (this.validateWebRequiredFields()) {
            this.saveProductPopinContent += this.$t('product.saveProductPopinContentWeb') + '<br>';
          }

          if (this.validateOmniRequiredFields()) {
            this.saveProductPopinContent += this.$t('product.saveProductPopinContentOmni') + '<br>';
          }

          if (!this.StringHelper.IsNullOrWhitespace(this.saveProductPopinContent)) {
            this.saveProductPopinContent += this.$t('product.saveProductPopinContentSaveMessage');
            return false;
          }
        } else if (this.areWebFieldsRequired) {
          if (this.validateWebRequiredFields()) {
            this.saveProductPopinContent = this.$t('product.saveProductPopinContentWeb') + '<br>';
            this.saveProductPopinContent += this.$t('product.saveProductPopinContentSaveMessage');
            return false;
          }
        } else if (this.areOmniFieldsRequired) {
          if (this.validateOmniRequiredFields()) {
            this.saveProductPopinContent = this.$t('product.saveProductPopinContentOmni') + '<br>';
            this.saveProductPopinContent += this.$t('product.saveProductPopinContentSaveMessage');
            return false;
          }
        }

        return true;
      },
      validateWebRequiredFields() {
        const isMissingDescription = this.StringHelper.IsNullOrWhitespace(this.getProductLocale('fr-CA').Description)
          && this.StringHelper.IsNullOrWhitespace(this.Product.GeneralStoreInformation.GeneralStoreDescription);

        return this.StringHelper.IsNullOrWhitespace(this.Product.Sku) ||
          this.StringHelper.IsNullOrWhitespace(this.Product.OmniMajorCategoryId) ||
          this.StringHelper.IsNullOrWhitespace(this.Product.OmniIntermediateCategoryId) ||
          this.StringHelper.IsNullOrWhitespace(this.Product.OmniMinorCategoryId) ||
          isMissingDescription ||
          !this.Product.FirstFile;
      },
      validateOmniRequiredFields() {
        var self = this;
        return this.StringHelper.IsNullOrWhitespace(this.Product.Sku) ||
          this.StringHelper.IsNullOrWhitespace(this.Product.Description) ||
          this.Product.Provider != null && this.StringHelper.IsNullOrWhitespace(this.Product.Provider.Number) ||
          this.StringHelper.IsNullOrWhitespace(this.Product.OmniMajorCategoryId) ||
          this.StringHelper.IsNullOrWhitespace(this.Product.OmniIntermediateCategoryId) ||
          this.StringHelper.IsNullOrWhitespace(this.Product.OmniMinorCategoryId) ||
          this.Product.Costings != null && this.StringHelper.IsNullOrWhitespace(this.Product.Costings.LaferteCost) ||
          this.Product.GeneralStoreInformation != null && this.StringHelper.IsNullOrWhitespace(this.Product.GeneralStoreInformation.Alpha1) ||
          this.Product.GeneralStoreInformation != null && this.StringHelper.IsNullOrWhitespace(this.Product.GeneralStoreInformation.Search1) ||
          this.Product.GeneralStoreInformation != null && this.Product.GeneralStoreInformation.SalesUnit === this.SalesUnitEnum.None ||
          this.Product.StoreInformations != null && this.Product.StoreInformations.some(function (item) {
            return item.Status === self.InternalStatusCodeEnum.None
              || self.StringHelper.IsNullOrWhitespace(item.Price1)
              || self.StringHelper.IsNullOrWhitespace(item.Minimum)
              || self.StringHelper.IsNullOrWhitespace(item.Maximum);
          });
      },
      saveProduct() {
        if (this.isUpdateProcess) {
          this.$store.dispatch('product/UpdateProduct', this.Product);
        } else {
          this.$store.dispatch('product/AddProduct', this.Product);
        }
      },
      showDeleteProductPopin(productId) {
        this.productToDelete = productId;
        this.activeDeleteProductPopin = true;
      },
      showSyncPopin(productId) {
        this.productToSync = productId;
        this.activeSyncProductPopin = true;
      },
      deleteProduct(productId) {
        this.$store.dispatch('product/DeleteProducts', [productId]);
      },
      async syncProduct() {
        if (this.isSyncingProduct) {
          return;
        }

        if (this.Product.MissingWebFields.length > 0) {
          const missingFields = this.Product.MissingWebFields.filter(x => x.Field !== 'Image').map(x => this.$t(`field.${x.Field}`));
          this.syncMessage = this.$t('product.syncMissingFields', { productID: this.Product.ProductId, missingFields });
          this.showSyncResultModal = true;
          return;
        }

        this.syncMessage = null;
        this.isSyncingProduct = true;

        try {
          let response = await authenticatedAxios.post('/api/product/synchronizeProductToWeb', { productID: this.productToSync});
          this.isSyncingProduct = false;

          if (response.status === 200) {
            this.showSyncResultModal = true;
            this.syncMessage = this.$t('product.syncSuccess');
          }
        } catch (error) {
          this.isSyncingProduct = false;
          this.showSyncResultModal = true;

          if (error.response && error.response.data) {
            this.syncMessage = error.response.data;
          }
          else {
            this.syncMessage = this.$t('product.syncError');
          }
        }
      },
      async uploadFile(e) {
        var self = this;
        self.imageValidationRequired = true;
        self.hasReceivedProductFile = true;
        const { valid } = await this.$refs.imageProvider.validate(e);
        if (valid) {
          const file = e.target.files[0];
          const reader = new FileReader();
          if (!self.Product.FirstFile) {
            self.Product.FirstFile = new FileModel();
          }
          self.Product.FirstFile.Name = file.name;
          self.Product.FirstFile.Type = self.FileTypeEnum.Product;
          self.Product.FirstFile.IsActive = true;
          reader.readAsDataURL(file);
          reader.onload = e => {
            self.Product.FirstFile.Content = e.target.result.split(',')[1];
          };
        }
      },
      async deleteFile(fileID) {
        if (fileID !== null && fileID !== undefined) {
          const fileToDelete = {
            id: fileID
          };
          await this.$store.dispatch('product/DeleteImage', fileToDelete);
          window.location.reload();
        }
      },
      async uploadSecondFile(e) {
        var self = this;
        self.secondFileValidationRequired = true;
        self.hasReceivedProductSecondFile = true;
        const { valid } = await this.$refs.imageProvider.validate(e);
        if (valid) {
          const file = e.target.files[0];
          const reader = new FileReader();
          if (!self.Product.SecondFile) {
            self.Product.SecondFile = new FileModel();
          }
          self.Product.SecondFile.Name = file.name;
          self.Product.SecondFile.Type = self.FileTypeEnum.SecondFile;
          self.Product.SecondFile.IsActive = true;
          reader.readAsDataURL(file);
          reader.onload = e => {
            self.Product.SecondFile.Content = e.target.result.split(',')[1];
          };
        }
      },
      async uploadThirdFile(e) {
        var self = this;
        self.thirdFileValidationRequired = true;
        self.hasReceivedProductThirdFile = true;
        const { valid } = await this.$refs.imageProvider.validate(e);
        if (valid) {
          const file = e.target.files[0];
          const reader = new FileReader();
          if (!self.Product.ThirdFile) {
            self.Product.ThirdFile = new FileModel();
          }
          self.Product.ThirdFile.Name = file.name;
          self.Product.ThirdFile.Type = self.FileTypeEnum.ThirdFile;
          self.Product.ThirdFile.IsActive = true;
          reader.readAsDataURL(file);
          reader.onload = e => {
            self.Product.ThirdFile.Content = e.target.result.split(',')[1];
          };
        }
      },
      async uploadFourthFile(e) {
        var self = this;
        self.fourthFileValidationRequired = true;
        self.hasReceivedProductFourthFile = true;
        const { valid } = await this.$refs.imageProvider.validate(e);
        if (valid) {
          const file = e.target.files[0];
          const reader = new FileReader();
          if (!self.Product.FourthFile) {
            self.Product.FourthFile = new FileModel();
          }
          self.Product.FourthFile.Name = file.name;
          self.Product.FourthFile.Type = self.FileTypeEnum.FourthFile;
          self.Product.FourthFile.IsActive = true;
          reader.readAsDataURL(file);
          reader.onload = e => {
            self.Product.FourthFile.Content = e.target.result.split(',')[1];
          };
        }
      },
      async uploadFifthFile(e) {
        var self = this;
        self.fifthFileValidationRequired = true;
        self.hasReceivedProductFifthFile = true;
        const { valid } = await this.$refs.imageProvider.validate(e);
        if (valid) {
          const file = e.target.files[0];
          const reader = new FileReader();
          if (!self.Product.FifthFile) {
            self.Product.FifthFile = new FileModel();
          }
          self.Product.FifthFile.Name = file.name;
          self.Product.FifthFile.Type = self.FileTypeEnum.FifthFile;
          self.Product.FifthFile.IsActive = true;
          reader.readAsDataURL(file);
          reader.onload = e => {
            self.Product.FifthFile.Content = e.target.result.split(',')[1];
          };
        }
      },
      async uploadSixthFile(e) {
        var self = this;
        self.sixthFileValidationRequired = true;
        self.hasReceivedProductSixthFile = true;
        const { valid } = await this.$refs.imageProvider.validate(e);
        if (valid) {
          const file = e.target.files[0];
          const reader = new FileReader();
          if (!self.Product.SixthFile) {
            self.Product.SixthFile = new FileModel();
          }
          self.Product.SixthFile.Name = file.name;
          self.Product.SixthFile.Type = self.FileTypeEnum.SixthFile;
          self.Product.SixthFile.IsActive = true;
          reader.readAsDataURL(file);
          reader.onload = e => {
            self.Product.SixthFile.Content = e.target.result.split(',')[1];
          };
        }
      },
      async uploadFlyerImage(e) {
        var self = this;
        self.imageValidationRequired = true;
        self.hasReceivedFlyerImage = true;
        const { valid } = await this.$refs.flyerImageProvider.validate(e);
        if (valid) {
          const image = e.target.files[0];
          const reader = new FileReader();
          if (!self.Product.FlyerImage) {
            self.Product.FlyerImage = new FileModel();
          }
          self.Product.FlyerImage.Name = image.name;
          self.Product.FlyerImage.Type = self.FileTypeEnum.Flyer;
          self.Product.FlyerImage.IsActive = true;
          reader.readAsDataURL(image);
          reader.onload = e => {
            self.Product.FlyerImage.Content = e.target.result.split(',')[1];
          };
        }
      },
      getProviders() {
        this.$store.dispatch('provider/GetAllProviders');
      },
      getProduct(productId) {
        this.$store.dispatch('product/GetProduct', productId);
      },
      getProductLocale(language) {
        const productLocale = this.Product.Locales.find(locale => locale.Locale === language);

        if (productLocale) {
          return productLocale;
        }

        let newLocale = new ProductLocaleModel();
        newLocale.Locale = language;
        this.Product.Locales.push(newLocale);

        return newLocale;
      },
      getStoreInformation(companyBranch) {
        const storeInformation = this.Product.StoreInformations.find(storeInformation => storeInformation.Branch === companyBranch);

        if (storeInformation) {
          return storeInformation;
        }

        let newStoreInformation = new StoreInformationModel();
        newStoreInformation.Branch = companyBranch;
        newStoreInformation.Status = 3;
        newStoreInformation.Price1 = 0;
        newStoreInformation.Minimum = 0;
        newStoreInformation.Maximum = 0;
        this.Product.StoreInformations.push(newStoreInformation);

        return newStoreInformation;
      },
      show(allowedViewModes) {
        const viewModes = Array.isArray(allowedViewModes) ? allowedViewModes : [];
        return this.viewMode === this.ProductViewModeEnum.None || viewModes.includes(this.viewMode);
      }
    },
    watch: {
      '$store.state.product.isProductAdded'(val) {
        if (val.isUpdated) {
          this.$router.push({ name: this.$getLocalRouteName('products') });
        }
      },
      '$store.state.product.isProductUpdated'(val) {
        if (val.isUpdated) {
          this.$router.push({ name: this.$getLocalRouteName('products') });
        }
      },
      '$store.state.provider.activeProviders'(val) {
        if (val) {
          this.providersAutocomplete = val;
        }
      },
      '$store.state.product.errorProductAdded'(val) {
        if (val) {
          const error = i18n.t('error.skualreadyexists', i18n.locale);
          this.$refs.addProductObserver.refs.Sku.applyResult({
            errors: [error],
            valid: false,
            failedRules: {}
          });
        }
      },
      async '$store.state.product.activeProduct'(val) {
        if (val) {
          this.Product = val;
          this.Product.MemberType = this.$store.state.app.userInfo.MemberType;
          this.fileValidationRequired = false;
          this.secondFileValidationRequired = false;
          this.thirdFileValidationRequired = false;
          this.fourthFileValidationRequired = false;
          this.fifthFileValidationRequired = false;
          this.sixthFileValidationRequired = false;
          this.isUpdateProcess = true;
          this.pageTitle = this.$t('product.modificationTitle') + val.Sku;

          await this.$nextTick();
          this.activateMapperCategory = true;
        }
      },
      '$store.state.product.isProductsDeleted': function (val) {
        if (val.isUpdated) {
          this.$router.push({ name: `${this.$root.$i18n.locale}.products` });
        }
      }
    }
  };
</script>

<style lang="scss">
  .flexTitle {
    display: flex;
  }

  .flexSpaceBetween {
    display: flex;
    justify-content: space-between;
  }

  .publicationStatus {
    margin-right: 40px;
  }

  .uploading-image {
    display: flex;
  }

  .md-tabs.md-theme-default .md-tabs-navigation .md-button .msgError {
    color: #ff1744;
  }

  .blockElement {
    margin-bottom: 1.00rem;
  }

    .blockElement .hasError {
      label

  {
    color: #ff1744;
  }

  .btn-group .dropdown-toggle {
    border-color: #ff1744;
  }

  .msgError {
    color: #ff1744;
  }

  }

  .md-card {
    box-shadow: none;
  }

  .addproduct .md-card-header {
    border-bottom: 1px solid #dadada;
    margin-bottom: 1.50rem;
    padding: 1.50rem 3.00rem;
  }

  .addproduct > span > .md-layout {
    width: 100%;
    margin: 0 auto;
  }

  .addproduct .md-layout .md-card-content {
    padding: 0;
    font-size: 1.00rem;
    color: #2a2a2a;
  }

  .addproduct .titleSection {
    height: 113px;
    padding: 1.50rem 3.00rem;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px solid #dadada;
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

    .addproduct .titleSection h1 {
      font-size: 2.005rem;
      margin: 0 auto;
      color: #2a2a2a;
      font-weight: 700;
    }

  .addproduct .btnSection {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;

    button:disabled {
      background: #E6E6E6;
      border-color: #E6E6E6;
      color: #BCBCBC;
    }
  }

  .addproduct .md-display-1 {
    font-size: 1.75rem;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 1.00rem;
  }

  .addproduct .md-field.md-theme-default:before {
    background-color: #2a2a2a;
  }

  .addproduct .md-field.md-theme-default.md-focused label {
    color: #2a2a2a;
  }

  .addproduct .md-field.md-theme-default.md-focused .md-input,
  .addproduct .md-field.md-theme-default.md-focused .md-textarea {
    color: #2a2a2a;
  }

  .addproduct .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
    border-color: #2a2a2a;
  }

  .addproduct .md-layout.md-gutter.blockElement {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .addproduct .section {
    border-bottom: 1px solid #dadada;
    margin-bottom: 1.50rem;
    padding: 1.50rem 3.00rem;
  }

    .addproduct .section.noPadding .md-layout.md-gutter > .md-layout-item {
      padding: 0;
    }

      .addproduct .section.noPadding .md-layout.md-gutter > .md-layout-item:nth-child(odd) {
        padding-right: 1.250rem;
      }

      .addproduct .section.noPadding .md-layout.md-gutter > .md-layout-item:nth-child(even) {
        padding-left: 1.250rem;
      }

      .addproduct .section.noPadding .md-layout.md-gutter.blockElement > .md-layout-item,
      .addproduct .section.noPadding .md-layout.md-gutter > .md-layout-item.width100 {
        padding-right: 0;
      }

  .addproduct .btn-group .dropdown-toggle:hover, .btn-group .dropdown-toggle.select {
    border-color: #2a2a2a;
  }

  .addproduct .btn-group .dropdown-menu li a:hover {
    background-color: #e20613;
  }

  .addproduct .md-layout.md-gutter.blockElement .md-layout-item {
    padding: 0;
  }

  .addproduct .styleSelect select {
    background: transparent;
    border: none;
    width: 100%;
    border: 0.0625rem solid #EAEAEA;
    border-radius: 0.625rem;
    cursor: pointer;
    padding: 0 0.875rem;
    height: 3.125rem;
    line-height: 3.125rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #000;
    position: relative;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

    .addproduct .styleSelect select:focus {
      outline: none;
    }

    .addproduct .styleSelect select::-ms-expand {
      display: none;
    }

    .addproduct .styleSelect select:hover,
    .addproduct .styleSelect select:active {
      border-color: #2a2a2a;
    }

  .addproduct .styleSelect .md-layout-item > div > div {
    position: relative;
  }

    .addproduct .styleSelect .md-layout-item > div > div:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #2a2a2a;
      display: inline-block;
      font-size: 1em;
      font-weight: normal;
      position: absolute;
      right: 1rem;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      pointer-events: none;
    }

  .addproduct .md-switch.md-theme-default.md-checked .md-switch-container {
    background-color: rgba(42, 42, 42, 0.5);
  }

  .addproduct .md-switch.md-theme-default.md-checked .md-switch-thumb {
    background-color: #2a2a2a;
  }

  .addproduct .md-headline {
    font-size: 1.5rem;
    color: #2a2a2a;
    font-weight: 700;
    margin-bottom: 1.00rem;
  }

  .addproduct .succursale {
    padding-bottom: 1.50rem;
  }

  .addproduct .md-card-actions {
    padding: 1.50rem 3.00rem;
  }

  .addproduct .md-button.md-theme-default.md-raised:not([disabled]).md-primary {
    width: 100%;
    font-weight: bold;
    color: #fff;
    background: #e20613;
    padding: 1rem 1.5rem;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    transition: .25s;
    height: auto;
    max-width: 225px;
    box-shadow: none;
    margin: 0;
  }

    .addproduct .md-button.md-theme-default.md-raised:not([disabled]).md-primary:hover {
      background: #86040b;
    }

  .addproduct .md-field.md-theme-default.md-invalid label,
  .addproduct .md-field.md-theme-default.md-invalid .md-error {
    color: #FF5F5F;
  }

  .addproduct .md-field.md-theme-default.md-invalid:after {
    background-color: #FF5F5F;
  }

  .addproduct .btn.alt {
    width: 100%;
    font-weight: bold;
    color: #e20613;
    background: #fff;
    border: 2px solid #e20613;
    padding: 0.8750rem 1.5rem;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    transition: .25s;
    height: auto;
    max-width: 225px;
    box-shadow: none;
    box-sizing: border-box;
    margin: 0;
    margin-right: 1.00rem;
  }

  .addproduct .btn.alt:hover {
    background: #86040b;
    border-color: #86040b;
    color: #fff;
  }

  .addproduct .isRequired label {
    color: #FF5F5F;
  }

  .addproduct .isRequired input::placeholder {
    color: #FF5F5F !important;
  }

  .addproduct .isRequired.md-field.md-theme-default:after,
  .addproduct .isRequired .md-field.md-theme-default:after {
    background-color: #FF5F5F;
  }

  .addproduct .styleSelect .isRequired select,
  .addproduct .isRequired .btn-group .dropdown-toggle {
    border-color: #FF5F5F;
    color: #FF5F5F;
  }
</style>

